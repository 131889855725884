import React, { useState, useEffect } from 'react';
import './RideLandingPageFour.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function RideLandingPageFour() {
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "rideLandingPageFour"][0]')
            .then((data) => {
                setPageData(data);
            })
            .catch((error) => console.error('Error fetching page data:', error));
    }, []);

    if (!pageData) {
        return <div></div>;
    }

    const { titlePart1, titlePart2, image1, image2, image3, count1, count2, count3, tiffinCenterText, deliveryPartnersText, stuffinUsersText } = pageData;
    
    // Function to split string and insert <br> tag
    const splitStringWithBr = (str) => {
        const middleIndex = Math.ceil(str.length / 2);
        const firstHalf = str.slice(0, middleIndex);
        const secondHalf = str.slice(middleIndex);
        return `${firstHalf}<br>${secondHalf}`;
    };

    return (
        <div id='ride-landing-page-four'>
            <h1>{titlePart1}<span>{titlePart2}</span></h1> 
            <div id='ride-landing-page-four-conatiner'>
                <div>
                    <div id='tiffin-center'>
                        <img src={urlFor(image1)} alt='/' />
                        <div>
                            <h5>{count1}</h5>
                            <p dangerouslySetInnerHTML={{ __html: splitStringWithBr(tiffinCenterText) }} />
                        </div>
                    </div>
                    <div id='delivery-partner'>
                        <img src={urlFor(image2)}  alt='/' />
                        <div>
                            <h5>{count2}</h5>
                            <p dangerouslySetInnerHTML={{ __html: splitStringWithBr(deliveryPartnersText) }} />
                        </div>
                    </div>
                </div>
                
                <div id='stuffin-users'>
                    <img src={urlFor(image3)}   alt='/' />
                    <div>
                        <h5>{count3}</h5>
                        <p dangerouslySetInnerHTML={{ __html: splitStringWithBr(stuffinUsersText) }} />
                    </div>
                </div>
            </div>
        </div >
    )
}
