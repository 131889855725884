// PartnerLandingPageFive.js

import React, { useEffect, useState } from 'react';
import './PartnerLandingPageFive.css';
import client from './../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function PartnerLandingPageFive() {
  const [partnerData, setPartnerData] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "partnerLandingPageFive"][0]')
      .then((result) => {
        // console.log('Fetched data:', result);
        setPartnerData(result);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (!partnerData) {
    return <div></div>;
  }

  const { title, description, points,roundPhn } = partnerData;

  return (
    <div id='partner-landing-page-five'>
      <div id='partner-landing-page-five-left'>
        <div id='partner-landing-page-five-left-heading'>
          <h1>{title}</h1>
          <p>{description}</p>
        </div>
        {points.map((point, index) => (
          <div key={index} id='partner-landing-page-five-left-points'>
            <div id='icon'>
              <img src={urlFor(point.icon)} alt='/' />
            </div>
            <div id='sign-up-description'>
              <h2>{point.title}</h2>
              <p>{point.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div id='partner-landing-page-five-right'>
        <img src={urlFor(roundPhn)}/>
      </div>
    </div>
  );
}



// import React from 'react';
// import './PartnerLandingPageFive.css';
// import frame1 from './Frame 997 (1).svg';
// import frame2 from './Frame 997.svg';
// import roundPhn from '../../assets/Frame 996.svg';

// export default function PartnerLandingPageFive() {
//     return (
//         <div id='partner-landing-page-five'>
//             <div id='partner-landing-page-five-left'>
//                 <div id='partner-landing-page-five-left-heading'>
//                     <h1>What do you get on sign-up?</h1>
//                     <p>Stuffin enables you to get 45% more revenue, 6x new customers and boost your brand
//                         visibility by providing insights to improve your business.</p>
//                 </div>
//                 <div id='partner-landing-page-five-left-points'>
//                     <div id='icon'>
//                         <img src={frame2} alt='/' />
//                     </div>
//                     <div id='description-1'>
//                         <h2>Tiffen center partner app</h2>
//                         <p>Unlock seamless management of orders and customer interactions with the Stuffin Partner App. A tool designed for tiffin centres to simplify, streamline, and succeed.</p>
//                     </div>
//                 </div>
//                 <div id='partner-landing-page-five-left-points'>
//                     <div id='icon'>
//                         <img src={frame1} alt='/' />
//                     </div>
//                     <div id='description-2'>
//                         <h2>Partner dashboard</h2>
//                         <p>Navigate your business insights effortlessly with Stuffin’s Partner Dashboard. A one-stop solution to monitor orders, track earnings, and optimize your tiffin service efficiently.</p>
//                     </div>
//                 </div>

//             </div>
//             <div id='partner-landing-page-five-right'>
//                 <img src={roundPhn} alt="/" />
//             </div>
//         </div>
//     )
// }