import React from 'react';
import { useEffect, useState } from 'react';
import PartnerlandingPageOne from '../components/partnerWithUs/PartnerlandingPageOne';
import Navbar from '../components/navbar/Navbar';
import PartnerLandingPageTwo from './partnerWithUs/PartnerLandingPageTwo';
import PartnerLandingPageThree from './partnerWithUs/PartnerLandingPageThree';
import PartnerLandingPageFour from './partnerWithUs/PartnerLandingPageFour';
import LandingPageThree from '../components/homePage/thirdLandingPage/LandingPageThree.js';
// import AdLandingPageTwo from '../components/advertiesWithUs/adLandingPageTwo/AdLandingPageTwo';
import ReusableComponents from './commonReusableComponents/ReusableComponents';
import './HomePage.css';
import PartnerLandingPageFive from './partnerWithUs/PartnerLandingPageFive';
import client from '../sanityConfig/sanityClient.js';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}


export default function PartnerWithUs() {

  const [faqItems, setFaqItems] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState('')
  

  let fetcheFaqItems = [];


  useEffect(() => {
    let passedValue = localStorage.getItem('myData');
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem('myData', "true");
    }
    client
      .fetch('*[_type == "faqPartnerPageSchema"][0]')
      .then((data) => {
        setFaqItems(data["faqItems"]);
        fetcheFaqItems = data["faqItems"];
      })
      .catch((error) => console.error('Error fetching about data:', error)).finally(() => { setFaqItems(faqItems); });

    client.fetch('*[_type == "backgroundImageHome"]{backgroundImage}')
      .then(data => {
        if (data.length > 0) {
          // console.log("bg: ", data);
          setBackgroundImage(urlFor(data[0].backgroundImage));
        }
      })
      .catch(error => console.error('Error fetching background image and dynamic text:', error));


    const intervalId = setInterval(() => {
      // Place your code here. This block will run every 30 seconds

      // Anonymous self-invoking function
      (function () {
        setFaqItems(fetcheFaqItems)
      })();

    }, 30); // 30000 ms = 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);

  }, []);
  return (
    <>

      <div className='background-style' style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Navbar />
        <br />
        <PartnerlandingPageOne />
        <PartnerLandingPageTwo />
      </div>
      <PartnerLandingPageThree />
      <PartnerLandingPageFour />
      <LandingPageThree />
      <PartnerLandingPageFive />
      {faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
    </>
  )
}
