import React, { useState, useEffect } from 'react';
import LandingPageOne from '../components/homePage/firstLandingPage/LandingPageOne';
import LandingPageTwo from '../components/homePage/secondLandingPage/LandingPageTwo';
import LandingPageThree from '../components/homePage/thirdLandingPage/LandingPageThree.js';
import LandingPageSix from '../components/homePage/sixthLandingPage/LandingPageSix.js';
import ReusableComponents from './commonReusableComponents/ReusableComponents';
import Navbar from '../components/navbar/Navbar';
import './HomePage.css';
import client from '../sanityConfig/sanityClient.js';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}


export default function HomePage() {
  const [faqItems, setFaqItems] = useState([]);
  const [backgroundImage, setBackgroundImage] = useState('');
  // const [loading, setLoading] = useState(true);

  let fetcheFaqItems = [];

  useEffect(() => {
    client
    .fetch('*[_type == "faqHomePageSchema"][0]')
    .then((data) => {
      setFaqItems(data["faqItems"]);
      fetcheFaqItems = data["faqItems"];
    })
    .catch((error) => console.error('Error fetching about data:', error)).finally(() => {setFaqItems(faqItems); });

    client.fetch('*[_type == "backgroundImageHome"]{backgroundImage}')
    .then(data => {
        if (data.length > 0) {
            // console.log("bg: ", data);
            setBackgroundImage(urlFor(data[0].backgroundImage));
        }
    })
    .catch(error => console.error('Error fetching background image and dynamic text:', error));

      const intervalId = setInterval(() => {
        (function() {
          setFaqItems(fetcheFaqItems)
        })();
  
      }, 30); // 30000 ms = 30 seconds
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <div className='background-style' style={{ backgroundImage: `url(${backgroundImage})` }}>
        <Navbar />  
        <br />
        <LandingPageOne />
        <LandingPageTwo />
      </div>
      <br />
      <LandingPageThree />
      <LandingPageSix />
      {/* Conditionally render ReusableComponents based on loading state */}
      { faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
      {/* {console.log(window.scrollY)} */}
    </>
  );
}


