
// PartnerLandingPageThree.js

import React, { useEffect, useState } from 'react';
import './PartnerLandingPageThree.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function PartnerLandingPageThree() {
    const [partnerData, setPartnerData] = useState(null);
    const [backgroundImage, setBackgroundImage] = useState(''); 

    useEffect(() => {
        client
            .fetch('*[_type == "partnerLandingPageThree"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setPartnerData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));

            client.fetch('*[_type == "backgroundImagePartner"]{backgroundImage}')
            .then(data => {
                if (data.length > 0) {
                    // console.log("bg: ", data);
                    setBackgroundImage(urlFor(data[0].backgroundImage));
                }
            })
            .catch(error => console.error('Error fetching background image and dynamic text:', error));


    }, []);

    if (!partnerData) {
        return <div></div>;
    }

    const { title, steps } = partnerData;

    return (
        <div id='partner-landing-page-three' style={{ backgroundImage: `url(${backgroundImage})` }} >
            <h2>{title}</h2>
            <div id='steps-container'>
                {steps.map((step, index) => (
                    <div key={index} id='step'>
                        <img src={urlFor(step.image)} alt={`Step ${step.stepNumber}`} />
                        <span>Step {step.stepNumber}</span>
                        <h6>{step.title}</h6>
                        <p>{step.description}</p>
                    </div>
                ))}
            </div>
        </div>
    );
}



// import React from 'react';
// import './PartnerLandingPageThree.css';
// import step1Img from './card item 1 (2).svg'
// import step2Img from './card item 1.svg';
// import step3Img from './card item 1 (1).svg';

// export default function PartnerLandingPageThree() {
//     return (
//         <div id='partner-landing-page-three'>
//             <h2>How it <span>works</span>?</h2>
//             <div id='steps-container'>
//                 <div id='step'>
//                     <img src={step1Img} alt='/' />
//                     <span>Step 1</span>
//                     <h6>Register on stuffin</h6>
//                     <p>Help users discover your place by <br />creating a listing on Stuffin</p>
//                 </div>
//                 <div id='step'>
//                     <img src={step2Img} alt='/' />
//                     <span>Step 2</span>
//                     <h6>Get your KYC done</h6>
//                     <p>And deliver orders to millions of <br />customers with ease</p>
//                 </div>
//                 <div id='step'>
//                     <img src={step3Img} alt='/' />
//                     <span>Step 3</span>
//                     <h6>Start receiving orders online</h6>
//                     <p>Manage orders on our partner app, <br />web dashboard or API partners</p>
//                 </div>
//             </div>
//         </div>
//     )
// }
