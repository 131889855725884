import React, { useEffect, useState } from 'react';
import './AdLandingTwo.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function AdLandingPageTwo() {
  const [adData, setAdData] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "adLandingPageTwo"][0]')
      .then((data) => {
        console.log('Fetched ad data:', data);
        setAdData(data);
      })
      .catch((error) => console.error('Error fetching ad data:', error));
  }, []);

  if (!adData) {
    return <div></div>;
  }

  const { heading, points, rightImage, scrollDownImage } = adData;

  function scrollToAdvertiesSection() {
    const advertiesSection = document.getElementById('add-landing-page-two-right');

    if (advertiesSection) {
      advertiesSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }

  return (
    <div id='add-landing-page-two'>
      <div id='add-landing-page-two-left'>
        <div id='add-landing-page-two-left-heading'>
          <h1>{heading}</h1>
          <p>{points.intro}</p>
        </div>
        {points.points.map((point, index) => (
          <div key={index} id='add-landing-page-two-left-points'>
            <div id='icons'>
              <img src={urlFor(point.icon)} alt='/' />
            </div>
            <div id='description'>
              <h2>{point.heading}</h2>
              <p>{point.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div id='add-landing-page-two-right'>
        <img src={urlFor(rightImage)} alt='round-phone' />
      </div>
      <button id='mobile-view-adverties-btn' onClick={scrollToAdvertiesSection}  onTouchStart={scrollToAdvertiesSection}   style={{ cursor: 'pointer' }}>
        <img src={urlFor(scrollDownImage)} alt='scrool-down' />
      </button>
    </div>
  );
}




// import React from 'react';
// import './AdLandingTwo.css';
// import scroolDownImg from './arrow-down.svg';
// import firstIcon from './assets/icon-1.svg';
// import secondIcon from './assets/icon-2.svg';
// import thirdIcon from './assets/icon-3.svg';
// import fourthIcon from './assets/icon-4.svg';
// import roundPhn from '../../../assets/Frame 996.svg';

// export default function AdLandingPageTwo() {

//     function scrollToAdvertiesSection() {
//         const advertiesSection = document.getElementById("add-landing-page-two-right");

//         if (advertiesSection) {
//             advertiesSection.scrollIntoView({
//                 behavior: 'smooth',
//             });
//         }
//     }

//     return (
//         <div id='add-landing-page-two'>
//             <div id='add-landing-page-two-left'>
//                 <div id='add-landing-page-two-left-heading'>
//                     <h1>Why advertise on Stuffin?</h1>
//                     <p>Stuffin enables you to get 45% more revenue, 6x new customers and boost your brand
//                         visibility by providing insights to improve your business.</p>
//                 </div>
//                 <div id='add-landing-page-two-left-points'>
//                     <div id='icons'>
//                         < img src={firstIcon} alt='/' />
//                     </div>
//                     <div id='description'>
//                         <h2>Unleash Your Potential</h2>
//                         <p>Unlock the door to a vast customer base of students and working professionals who value the authenticity of home-cooked meals, amplifying your reach and impact in the market.</p>
//                     </div>
//                 </div>
//                 <div id='add-landing-page-two-left-points'>
//                     <div id='icons'>
//                         <img src={secondIcon} alt='/' />
//                     </div>
//                     <div id='description'>
//                         <h2>Seamless Onboarding</h2>
//                         <p>Experience a smooth and straightforward onboarding process, with a dedicated team at Stuffin ensuring that your tiffin service is showcased effectively and appealingly to our subscribers.</p>
//                     </div>
//                 </div>

//                 <div id='add-landing-page-two-left-points'>
//                     <div id='icons'>
//                         <img src={thirdIcon} alt='/' />
//                     </div>
//                     <div id='description'>
//                         <h2>Boost Your Business</h2>
//                         <p>Leverage the power of Stuffin’s platform to enhance your visibility, drive customer engagement, and boost your orders, propelling your tiffin service to new heights of success.</p>
//                     </div>
//                 </div>

//                 <div id='add-landing-page-two-left-points'>
//                     <div id='icons'>
//                         <img src={fourthIcon} alt='/' />
//                     </div>
//                     <div id='description'>
//                         <h2>Be a Part of a Culinary Community</h2>
//                         <p>Join a vibrant community of culinary enthusiasts and tiffin service providers, fostering connections, sharing experiences, and contributing to a collective culinary journey that celebrates diversity and unity.</p>
//                     </div>
//                 </div>
//             </div>
            
//             <div id='add-landing-page-two-right'>
//                 <img src={roundPhn} alt="/" />
//             </div>

//             <div id='mobile-view-adverties-btn' onClick={scrollToAdvertiesSection}>
//                <img src={scroolDownImg} alt="scrool-down" />
//             </div>
//         </div>
//     )
// }