import React, { useState, useEffect } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import './RideLandingPageTwo.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function RideLandingPageTwo() {
    const [carouselItems, setCarouselItems] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        // Fetch carousel items from Sanity
        client
            .fetch('*[_type == "rideLandingPageTwo"][0]')
            .then((data) => {
                if (data && data.carouselItems) {
                    setCarouselItems(data.carouselItems);
                }
            })
            .catch((error) => console.error('Error fetching carousel items:', error));
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setActiveIndex((prevIndex) => (prevIndex + 1) % carouselItems.length);
        }, 3000); // Change image every 3 seconds (adjust as needed)

        return () => {
            clearInterval(interval);
        };
    }, [carouselItems]);

    if (!carouselItems || carouselItems.length === 0) {
        return <div></div>;
    }

    return (
        <div id="ride-landing-page-two">
            <div id="carousel-container">
                <Carousel
                    showStatus={false}
                    showThumbs={false}
                    selectedItem={activeIndex}
                    interval={3000} // Change image every 3 seconds (adjust as needed)
                >
                    {carouselItems.map((item, index) => (
                        <div key={index} id="carousel-item">
                            <div id="image-container">
                                <img src={urlFor(item.image)} alt={item.text} />
                            </div>
                            <div>{item.text}</div>
                            <div id="dot-indicators">
                                {carouselItems.map((_, idx) => (
                                    <div
                                        key={idx}
                                        id={`dot ${idx === activeIndex ? 'active' : ''}`}
                                    ></div>
                                ))}
                            </div>
                        </div>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}





// import React, { useState, useEffect } from 'react';
// import 'react-responsive-carousel/lib/styles/carousel.min.css';
// import { Carousel } from 'react-responsive-carousel';
// import './RideLandingPageTwo.css';
// import walletImg from './fi_855279.svg';
// import workImg from './fi_463250.svg';
// import incentiveImg from './fi_545885.svg';

// export default function RideLandingPageTwo() {
//   const images = [walletImg, workImg, incentiveImg];
//   const text = [
//     'Flexible Earning Opportunities',
//     'Supportive Work Environments',
//     'Incentive Programs',
//   ];

//   const [activeIndex, setActiveIndex] = useState(0);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % images.length);
//     }, 3000); // Change image every 3 seconds (adjust as needed)

//     return () => {
//       clearInterval(interval);
//     };
//   }, [images.length]);

//   return (
//     <div id="ride-landing-page-two">
//       <div className="carousel-container">
//         <div>
//         <Carousel
//           showStatus={false}
//           showThumbs={false}
//           selectedItem={activeIndex}
//           interval={3000} // Change image every 3 seconds (adjust as needed)
//         >
//           {images.map((image, index) => (
//             <div key={index} className="carousel-item">
//               <div className="image-container">
//                 <img src={image} alt={text[index]} />
//               </div>
//               <div>
//               {text[index]}
//               </div>

//               <div className="dot-indicators">
//           {images.map((_,index) => (
//             <div
//             key={index}
//             className={`dot ${index === activeIndex ? 'active' : ''}`}
//           ></div>
//           ))}
//         </div>
//             </div>
//           ))}

//         </Carousel>
        
//         </div>
//     </div>

//     </div>
//   );
// }
