import React, { useState, useEffect } from 'react';
import './LandingPageEight.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function LandingPageEight() {
    const [landingPageData, setLandingPageData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "landingPageEight"][0]')
            .then((result) => {
                setLandingPageData(result);
            })
            .catch((error) => console.error('Error fetching landing page data:', error));
    }, []);

    if (!landingPageData) {
        return <div></div>;
    }

    const { heading, description, mobileImage, playStoreImage, playStoreImageUrl } = landingPageData;

    return (
        <div id='landing-page-eight'>
            <div id='page-eight-left-sec'>
                <img src={urlFor(mobileImage)} alt='Mobile' id="complicated-style" />
            </div>
            <div id='page-eight-right-sec'>
                <h1>{heading}</h1>
                <p id='fingertips'>{description}</p>
                <div id='playstore-link'>
                    <a href={playStoreImageUrl} target="_blank" rel="noopener noreferrer">
                        <img src={urlFor(playStoreImage)} alt='Play Store' />
                    </a>
                </div>
            </div>
        </div>
    );
}
