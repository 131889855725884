
import React, { useEffect, useState } from 'react';
import './PartnerLandingPageFour.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function PartnerLandingPageFour() {
    const [partnerData, setPartnerData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "partnerLandingPageFour"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setPartnerData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!partnerData) {
        return <div></div>;
    }

    const { title, orderingItems } = partnerData;

    return (
        <div id='partner-landing-page-four'>
            <div id='ordering-container'>
                <h1>{title}</h1>
                <p>Please Keep the documents ready for a smooth signup</p>
                <div id='singleline-ordering-container'>
                    {orderingItems.slice(0, 2).map((item, index) => (
                        <div key={index} id='seprate-order-container'>
                            <img src={urlFor(item.image)} alt='/' />
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
                <div id='singleline-ordering-container'>
                    {orderingItems.slice(2, 4).map((item, index) => (
                        <div key={index} id='seprate-order-container'>
                            <img src={urlFor(item.image)} alt='/' />
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
                <div id='singleline-ordering-container'>
                    {orderingItems.slice(4, 6).map((item, index) => (
                        <div key={index} id='seprate-order-container'>
                            <img src={urlFor(item.image)} alt='/' />
                            <p>{item.description}</p>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}




// import React from 'react';
// import './PartnerLandingPageFour.css';
// import yesTickImg from './fi_711239.svg';

// export default function PartnerLandingPageFour() {
//     return (
//         <div id='partner-landing-page-four'>
//             <div id='ordering-container'>
//                 <h1>Get started with online ordering</h1>
//                 <p>Please Keep the documents ready for a smooth signup</p>
//                 <div id='singleline-ordering-container'>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>FSSAI license copy</p>
//                     </div>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>PAN Card copy</p>
//                     </div>
//                 </div>
//                 <div id='singleline-ordering-container'>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>Regular GSTIN (if applicable)</p>
//                     </div>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>Bank account details</p>
//                     </div>
//                 </div>
//                 <div id='singleline-ordering-container'>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>Your Tiffin menu</p>
//                     </div>
//                     <div id='seprate-order-container'>
//                         <img src={yesTickImg} alt='/' />
//                         <p>Food images for top 5 items</p>
//                     </div>
//                 </div>
               
//             </div>
//         </div>
//     )
// }
