import React,{useEffect} from "react";
import Navbar from "./navbar/Navbar";
import LandingPageNine from "./homePage/ninthLangingPage/LandingPageNine";
import RefundPolicy from "./refundPolicy/RefundPolicy";

function RefundPolicyPage() {
    useEffect (() =>
    {
      let passedValue = localStorage.getItem('myData');
      if(passedValue !== "true"){
          window.location.reload();
          localStorage.setItem('myData', "true");
      }
    }, [])
  return (
    <>
      <Navbar />
      <RefundPolicy />
      <LandingPageNine />
    </>
  );
}

export default RefundPolicyPage;
