import React, { useEffect, useState } from 'react';
import './RideLandingPageOne.css';
// import { Link } from 'react-router-dom';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function RideLandingPageOne() {
    const [riderData, setRiderData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "riderLandingPageOne"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setRiderData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!riderData) {
        return <div></div>;
    }

    const {  
        riderHeroImage,
        riderHeroTitle,
        riderHeroRightTitle,
        riderContactDetail,
        buttonContact,
        signInLink,
        buttonText,
        signInLinkText,
        signInUrlText,
    } = riderData;
    // Check if riderHeroImage is defined before generating URL
    const imageUrl = riderHeroImage ? urlFor(riderHeroImage) : '';

    // Handle button click to navigate to buttonContact URL
    const handleContactButtonClick = () => {
        window.location.href = buttonContact;
    };

    // Handle click on "Sign in" text link to navigate to signInLink URL
    const handleSignInLinkClick = () => {
        window.location.href = signInLink;
    };

    return (
        <div id='ride-landing-page-one'>
            <div id='ride-landing-page-one-container'>
                <div id='ride-landing-page-one-left'>
                    <img src={imageUrl} alt='/riderHero' />
                    <h5>{riderHeroTitle}</h5>
                </div>
                <div id='ride-landing-page-one-right'>
                    <div>
                        <h5>{riderHeroRightTitle}</h5>
                        <h4>{riderContactDetail}</h4>
                        <button onClick={handleContactButtonClick}>{buttonText}</button>
                        <p>{signInLinkText}<span onClick={handleSignInLinkClick}>{signInUrlText}</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
}
