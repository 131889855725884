import React, {useEffect, useState} from 'react';
import './AdLandingPageFour.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function AdLandingPageFour() {
  const [contactData, setContactData] = useState(null);

useEffect(() => {
    client
      .fetch('*[_type == "adLandingPageFour"][0]')
      .then((data) => {
        // console.log('Fetched contact data:', data);
        setContactData(data);
      })
      .catch((error) => console.error('Error fetching contact data:', error));
  }, []);

  if (!contactData) {
    return <div></div>;
  }

  const { heading, contactUsNow, subheading, contactDetails, contactDetailsPhoneView } = contactData;

  return (
    <div id='ad-landing-page-four'>
      <h1>
        {heading}
        <span>{contactUsNow}</span>
      </h1>
      <h6>{subheading}</h6>
      <div id='contact-details'>
        {contactDetails.map((contact, index) => (
            <a key={index} id={contact.id} href={contact.url}>
            <div>
              <img src={urlFor(contact.icon)} alt='contact-icon' />
              <p>{contact.text}</p>
            </div>
          </a>
        ))}
      </div>
      <div id='contact-details-phone-view' >
        {contactDetailsPhoneView.map((contact, index) => (
            <a key={index} id={contact.id} href={contact.url}>
            <div>
              <img src={urlFor(contact.icon)} alt='contact-icon' />
              <p>{contact.text}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
  );
}





