import React, { useState, useEffect } from "react";
import "./ContactFirstLandigPage.css";
import client from "../../sanityConfig/sanityClient";
import imageUrlBuilder from "@sanity/image-url";
import FormfacadeEmbed from "@formfacade/embed-react";

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function ContactFirstLandingPage() {
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "contactForm"][0]')
      .then((data) => setPageData(data))
      .catch((error) => console.error("Error fetching page data:", error));
  }, []);

  if (!pageData) {
    return <div></div>;
  }

  const {
    title,
    titleDescription,
    contactBtnText,
    contactBtnIcon,
    contactHeroRightImage,
    contactSubHeroImage,
  } = pageData;

  const scrollToContactSection = () => {
    const contactSection = document.getElementById("contact-subHero");
    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <div className="contact-hero-container">
        <div className="contact-hero-left">
          <h1>{title}</h1>
          <p>{titleDescription}</p>
          <button className="contact-us-btn" onClick={scrollToContactSection}>
            <span>{contactBtnText}</span>
            <img src={urlFor(contactBtnIcon)} alt="Contact" />
          </button>
        </div>
        <div className="contact-hero-right">
          <img src={urlFor(contactHeroRightImage)} alt="Contact Hero" />
        </div>
      </div>

      <div id="contact-subHero">
        <div className="contact-subHero-left">
          <img src={urlFor(contactSubHeroImage)} alt="Contact Sub Hero" />
        </div>
        <div className="contact-subHero-right">
          <div className="form-wrapper">
            <FormfacadeEmbed
              formFacadeURL="https://formfacade.com/include/114601572845609710616/form/1FAIpQLSen5P3ft1ht0eQ8lnjiJDLFJpsxhcDLAg3k4LBzjJauD-TPmA/classic.js/?div=ff-compose"
              onSubmitForm={() => console.log("Form submitted")}
            />
          </div>
        </div>
      </div>
    </>
  );
}
