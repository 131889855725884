import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { useParams, BrowserRouter as Router ,useRoutes, useNavigate} from 'react-router-dom';
import HomePage from './components/HomePage';
import AdvertiesWithUs from './components/AdvertiesWithUs';
import PartnerWithUs from './components/PartnerWithUs';
import RideWithUs from './components/RideWithUs';
import AboutUsPage from './components/AboutUsPage';
import PolicyPageComponent from './components/PolicyPageComponent';
import BlogView from './components/blogPage/BlogView';
import ContactUs from './components/ContactUs';
import BlogBoxContainer from './components/blogPage/BlogBoxContainer';
import client from './sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { fetchPageContent } from './Backend/sanityBackend';
import RefundPolicyPage from './components/RefundPolicyPage';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}
const defaultRoutes = [
  { path: '/', element: <HomePage /> },
  { path: '/home', element: <HomePage /> },
  { path: '/partner', element: <PartnerWithUs /> },
  { path: '/rider', element: <RideWithUs /> },
  { path: '/about', element: <AboutUsPage /> },
  { path: '/contact', element: <ContactUs /> },
  { path: '/blog', element: <BlogView /> },
  { path: '/policy', element: <PolicyPageComponent /> },
  {path: '/refundPolicy', element: <RefundPolicyPage />},
  { path: '/advertise', element: <AdvertiesWithUs /> },
  { path: '/blogs/:blogId', element: <BlogBoxContainer /> },
];

const Initialize = () => {
  const [routes, setRoutes] = useState(defaultRoutes); 
  const { blogPath } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    fetchPageContent().then(data => {
      if (data) {
        const dynamicRoutes = [
          { path: '/', element: <HomePage /> },
          { path: `/${data.home ? data.home : 'home'}`, element: <HomePage /> },
          { path: `/${data.partner ? data.partner : 'partner'}`, element: <PartnerWithUs /> },
          { path: `/${data.rider ? data.rider : 'rider'}`, element: <RideWithUs /> },
          { path: `/${data.about ? data.about : 'about'}`, element: <AboutUsPage /> },
          { path: `/${data.contact ? data.contact : 'contact'}`, element: <ContactUs /> },
          { path: `/${data.blog ? data.blog : 'blog'}`, element: <BlogView /> },
          { path: `/${data.policy ? data.policy : 'policy'}`, element: <PolicyPageComponent /> },
          { path: `/${data.refundPolicy ? data.refundPolicy: 'refundPolicy'}`, element: < RefundPolicyPage />},
          { path: `/${data.advertise ? data.advertise : 'advertise'}`, element: <AdvertiesWithUs /> },
          { path: `/${data.blogs ? data.blogs : 'blogs'}/:blogId`, element: <BlogBoxContainer /> },          
        ];

        setRoutes(dynamicRoutes.length > 0 ? dynamicRoutes : defaultRoutes);
        document.title = data.title;
        document.querySelector('meta[name="description"]').setAttribute('content', data.description);

        if (data.favicon) {
          let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
          link.type = 'image/png';
          link.rel = 'shortcut icon';
          link.href = urlFor(data.favicon);
          document.getElementsByTagName('head')[0].appendChild(link);
        }

        if (data.header && data.header.length > 0) {
          data.header.forEach(headerHtml => {
            const div = document.createElement('div');
            div.innerHTML = headerHtml;
            while (div.children.length > 0) {
              document.head.appendChild(div.children[0]);
            }
          });
        }
      }
    })
    .catch(error => console.error("ErrorDocument 404 /notfound.html", error));

  }, [blogPath, navigate]);

  const element = useRoutes(routes); // Dynamically use routes based on the current state

  return element || <div></div>; // Show loading or fallback content as needed
};


const root = ReactDOM.createRoot(document.getElementById('root'));
      root.render(
        <React.StrictMode>
          <Router>
            <Initialize />
          </Router>
        </React.StrictMode>
);
