// PartnerLandingPageTwo.js

import React, { useEffect, useState } from 'react';
import './PartnerLandingPageTwo.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function PartnerLandingPageTwo() {
    const [partnerData, setPartnerData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "partnerLandingPageTwo"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setPartnerData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!partnerData) {
        return <div></div>;
    }

    const { title, description, supportItems } = partnerData;

    return (
        <div id='partner-landing-page-two'>
            <h1>{title}</h1>
            <p className='sub-title'>{description}</p>
            <div id='stuffin-insights'>
                {supportItems.map((item, index) => (
                    <a href={item.url} key={index} target="_blank" rel="noopener noreferrer" id='stuffin-support'>
                        <img src={urlFor(item.image)} alt='Support Image' />
                        <div id='stuffin-support-info'>
                            <h5>{item.title}</h5>
                            <p>{item.info}</p>
                        </div>
                    </a>
                ))}
            </div>
        </div>
    );
}
