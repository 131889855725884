import React, { useState, useEffect, useRef } from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function Navbar() {
  const [navbarContent, setNavbarContent] = useState(null);
  const [pageContent, setPageContent] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const navbarRef = useRef(null);

  useEffect(() => {
    // Fetch navbar content from Sanity CMS
    client
      .fetch('*[_type == "navbarContent"][0]')
      .then((data) => {
        setNavbarContent(data);
      })
      .catch((error) => console.error('Error fetching navbar content:', error));
    client
      .fetch('*[_type == "pageContent"][0]')
      .then((data) => {
        setPageContent(data);
      })
      .catch((error) => console.error('Error fetching navbar content:', error));

    // Add event listener to detect clicks outside of navbar right list
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      // Remove event listener when component unmounts
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      // Click occurred outside of navbar right list, close it
      setShowMenu(false);
    }
  };

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <div id='navbar'>
      {navbarContent && (
        <Link to='/home'>
          <img src={urlFor(navbarContent.logo)} alt='Logo' className='nav-logo' />
        </Link>
      )}

      <button className='hamburger-button' onClick={toggleMenu}>
        {navbarContent && (
          <img src={urlFor(navbarContent.hamburgerButton)} alt='menu-btn' />
        )}
      </button>

      <div ref={navbarRef} className={`nav-right-list ${showMenu ? 'active' : ''}`}>
        {pageContent && pageContent.home &&  <p>
          <Link to={`/${pageContent.home}`} onClick={() => setShowMenu(false)}>
            {navbarContent && navbarContent.homeLabel && navbarContent.homeLabel ? navbarContent.homeLabel : "Home"}
          </Link>
        </p>}
        {pageContent && pageContent.partner &&  <p>
          <Link to={`/${pageContent.partner}`} onClick={() => setShowMenu(false)}>
          {navbarContent && navbarContent.partnerLabel ? navbarContent.partnerLabel : 'Partner'}
          </Link>
        </p>}
        {pageContent && pageContent.rider &&  <p>
          <Link to={`/${pageContent.rider}`} onClick={() => setShowMenu(false)}>
          {navbarContent && navbarContent.riderLabel ? navbarContent.riderLabel : "Rider"}
          </Link>
        </p>}
        {pageContent && pageContent.about &&  <p>
          <Link to={`/${pageContent.about}`} onClick={() => setShowMenu(false)}>
          {navbarContent && navbarContent.aboutLabel ? navbarContent.aboutLabel : "About Us"}
          </Link>
        </p>}
        {pageContent && pageContent.contact &&  <p>
          <Link to={`/${pageContent.contact}`} onClick={() => setShowMenu(false)}>
          {navbarContent && navbarContent.contactLabel ? navbarContent.contactLabel : "Contact Us"}
          </Link>
        </p>}
        {pageContent && pageContent.blog &&  <p>
          <Link to={`/${pageContent.blog}`} onClick={() => setShowMenu(false)}>
          {navbarContent && navbarContent.blogLabel ? navbarContent.blogLabel : "Blogs"}
          </Link>
        </p>}

        <button className='cross-button' onClick={toggleMenu}>
          ✕
        </button>
      </div>
    </div>
  );
}
