import React, { useState, useEffect } from 'react';
import './LandingPageOne.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);
function urlFor(source) {
  return builder.image(source);
}

export default function LandingPageOne() {
  const [data, setData] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "landingPageOne"][0]')
      .then((result) => {
        // console.log('Fetched data:', result);
        setData(result);
      })
      .catch((error) => console.error('Error fetching data:', error));

  }, []);

  if (!data) {
    return <div></div>;
  }

  const { subHeading, heading, imageSection, rightSection, mobileViewText, mobileViewImages } = data;

  return (
    <div id='landing-page-one'>
      <div id='landing-page-one-left'>
        <span className='sub-heading'>
          {subHeading && <img src={urlFor(subHeading.image)} alt="hompage-medal" />}
          {subHeading && <p>{subHeading.text}</p>}
        </span>
        {heading && <p id='heading'>{heading}</p>}
      </div>
      <div id='landing-page-one-right'>
        {rightSection && <img src={urlFor(rightSection.bigPhoneImage)} alt='big-phone' className='big-phn' />}
      </div>

      {/* Mobile view code */}
      <>
        <div id='mobile-view'>
          <div id='hero-section'>
            {imageSection && <img src={urlFor(imageSection.logo)} alt='/' id='mobile-view-logo' />}
            {mobileViewImages && mobileViewImages.image1 && <img src={urlFor(mobileViewImages.image1)} alt='phn-big-img' className='small-phn' />}
          </div>
          <div id='hero-text'>
            {mobileViewText && <p>{mobileViewText.text}</p>}
            <h2>{mobileViewText.subtext}</h2>
            {imageSection && imageSection.playStore && (
              <a href={imageSection.playStore.url} target="_blank" rel="noopener noreferrer">
                <img src={urlFor(imageSection.playStore.image)} alt='PlayStore' />
              </a>
            )}
          </div>
          
          <span className='band-box'>
            {subHeading && <img src={urlFor(subHeading.image)} alt="hompage-medal" />}
            {subHeading && <p style={{ fontsize: '12px' }}>{subHeading.text}</p>}
          </span>

        </div>
      </>
    </div>
  );
}

