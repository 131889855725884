import React, { useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import './BlogBoxContainer.css';
import './BlogView.css'
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import ReusableComponents from '../commonReusableComponents/ReusableComponents';
import Navbar from '../navbar/Navbar';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function BlogBoxContainer({ blog }) {
    const [currentBlog, setCurrentBlog] = useState(null);
    const location = useLocation();
    const [backgroundImage, setBackgroundImage] = useState('');
    const [dynamicText, setDynamicText] = useState('');
    const [faqItems, setFaqItems] = useState([]);
    const blogPath = useParams(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (blog) {
            setCurrentBlog(blog);
        } else if (location.state) {
            setCurrentBlog(location.state);
        }

        if(blogPath.blogId )
        {
        
            client
            .fetch('*[_type == "blogContent"]')
            .then(data => {
                const allBlogs = data[0]?.blogBoxes || [];
                blog = allBlogs.filter(x => x.blogPath === blogPath.blogId)
                if(!blog || blog.length == 0){
                    alert('No Blogs at this path');
                    navigate('/');
                }
               
                setCurrentBlog(blog[0]);
                location.state = blog[0] ;
            })
            .catch(error => console.error('Error fetching blog data:', error));
        }
        client.fetch('*[_type == "faqBlogPageSchema"][0]')
            .then(data => {
                setFaqItems(data["faqItems"]);
            })
            .catch(error => console.error('Error fetching about data:', error));

        client.fetch('*[_type == "backgroundImage"]{backgroundImage, dynamicText}')
            .then(data => {
                if (data.length > 0) {
                    setBackgroundImage(urlFor(data[0].backgroundImage));
                    setDynamicText(data[0]?.dynamicText || '');
                }
            })
            .catch(error => console.error('Error fetching background image and dynamic text:', error));
    }, [blog, location.state ? location.state.blog : location.state]);

    if (!currentBlog) {
        return <div></div>;
    }

    return (
        <>
        {location.state && <Navbar/>}
            {location.state && (
                 <div className="blog-Page-hero">
                 <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
                 <h1>{dynamicText}</h1>
             </div>
            )}
            <div className="blog-box-container">
                {location.state &&  (
                        <div className="blog-box-heroSection">
                           <h1 className='blogTitle '>{currentBlog.limitedTitle}</h1>
                           <p className='blogDate'>{currentBlog.date}</p>
                           <h6 className='authorName'><span>By </span>{currentBlog.authorName}</h6>
                           <p className='smallDesc '>{currentBlog.smallDescription}</p>
                           <hr className="horizontal-line" />
                           <p className='topContent'>{currentBlog.topContent}</p>
                           <img src={urlFor(currentBlog.image)} alt="blog-image" />
                           <p className='bottomContent'>{currentBlog.bottomContent}</p>
                       </div>
               
                )}

                <div className='blog-box-data'>
                    {!location.state && (
                        <img src={urlFor(currentBlog.image)} alt="blog-image" />
                    )}
                    {!location.state && (
                        <h5>{currentBlog.authorName} | <span>{currentBlog.date}</span></h5>
                    )}
                    {!location.state && (
                        <h2>{currentBlog.limitedTitle}</h2>
                    )}
                    {!location.state && (
                        <h6>{currentBlog.smallDescription}</h6>
                    )}
                </div>
            </div>

            {location.state && faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
        </>
    );
}



