

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import './BlogView.css';
import Navbar from '../navbar/Navbar';
import BlogBoxContainer from './BlogBoxContainer';
import ReusableComponents from '../commonReusableComponents/ReusableComponents';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';
import { fetchBlogBackgroundImage, fetchBlogPageFaqSchema, fetchBlogs, fetchPageContent } from '../../Backend/sanityBackend';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function BlogView() {
    const [blogs, setBlogs] = useState([]);
    const [categories, setCategories] = useState([]);
    const [blogsToShow, setBlogsToShow] = useState(3); // Initial number of blogs to show
    const [totalBlogs, setTotalBlogs] = useState(0); // Total number of blogs
    const [faqItems, setFaqItems] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('All'); // Selected category
    const [backgroundImage, setBackgroundImage] = useState('');
    const [dynamicText, setDynamicText] = useState(''); // State for dynamic text
    const [currentPage, setCurrentPage] = useState(0); // Current page index for pagination
    const [isMobile, setIsMobile] = useState(false);
    const [pageContent, setPageContent] = useState(null);
    // const location = useLocation();

    useEffect(() => {
        fetchBlogs().then(({ allBlogs, uniqueCategories }) => {
            setTotalBlogs(allBlogs.length);
            setCategories(uniqueCategories);
            setBlogs(allBlogs);
        }).catch((error) => console.error('Error fetching blogs:', error));

        fetchPageContent().then(data => {
            setPageContent(data);
        }).catch((error) => console.error('Error fetching navbar content:', error));

        fetchBlogPageFaqSchema().then(data => {
            setFaqItems(data["faqItems"]);
        }).catch(error => console.error('Error fetching about data:', error));


        fetchBlogBackgroundImage().then(data => {
            if (data.length > 0) {
                setBackgroundImage(urlFor(data[0].backgroundImage));

                setDynamicText(data[0]?.dynamicText || '');
            }
        }).catch(error => console.error('Error fetching background image and dynamic text:', error));

        // Check if the screen size is mobile
        const handleResize = () => {
            setIsMobile(window.innerWidth < 600);
        };

        handleResize(); // Initial check
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };

    }, []);


    const handleLoadMore = () => {
        setBlogsToShow(prevBlogsToShow => prevBlogsToShow + 3);
    };

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setBlogsToShow(3);
    };

    const handlePrevPage = () => {
        if (currentPage > 0) {
            setSelectedCategory('All'); // Activate "All" category
            setCurrentPage(prevPage => prevPage - 1);
        }
    };

    const handleNextPage = () => {
        if ((currentPage + 1) * (isMobile ? 2 : 5) < categories.length) {
            setSelectedCategory('All'); // Activate "All" category
            setCurrentPage(prevPage => prevPage + 1);
        }
    };

    return (
        <>
            <Navbar />
            <div className="blog-page">
                <div className="blog-Page-hero">
                    <div className="background-image" style={{ backgroundImage: `url(${backgroundImage})` }} />
                    <h1>{dynamicText}</h1>
                </div>


                <div className='category-buttons'>
                    <button className="prev-button" onClick={handlePrevPage} disabled={currentPage === 0}>{"<"}</button>
                    <div className='caregory-list'>
                        {categories.slice(currentPage * (isMobile ? 2 : 5), (currentPage * (isMobile ? 2 : 5)) + (isMobile ? 2 : 5)).map(category => (
                            <p key={category} onClick={() => handleCategoryClick(category)} className={category === selectedCategory ? 'selected' : ''}>
                                {category}
                                {category === selectedCategory && <span key={`${category}-line`} className="active-line"></span>}
                            </p>
                        ))}

                    </div>
                    <button className="next-button" onClick={handleNextPage} disabled={(currentPage + 1) * (isMobile ? 2 : 5) >= categories.length}>{">"}</button>
                </div>
                <div className='blog-container'>
                    {blogs
                        .filter(blog => blog.category === selectedCategory || selectedCategory === 'All')
                        .slice(0, blogsToShow)
                        .map(blog => (
                            <div key={blog._id} className='blog-box'>
                                <Link to={`/${pageContent && pageContent.blogs ? pageContent.blogs : "blogs"}/${blog.blogPath}`} state={blog} className='blog-box-link'>
                                    <BlogBoxContainer blog={blog} />
                                </Link>
                            </div>
                        ))}
                </div>

                {blogsToShow < totalBlogs && (
                    <button className="load-more-button" onClick={handleLoadMore}>
                        Load More
                    </button>
                )}
            </div>
            {faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
        </>
    );
}
