import React, { useState, useEffect } from "react";
import "./LandingPageSeven.css";
import expandImg from "./expand-btn (2).svg";

export default function LandingPageSeven({ faqItems = [] }) {
  const [expandedItem, setExpandedItem] = useState(null);
  const [localFaqItems, setLocalFaqItems] = useState([]);

  useEffect(() => {
    // Update local state only if faqItems prop changes
    setLocalFaqItems(faqItems);
  }, [faqItems]);

  const toggleExpand = (item) => {
    if (expandedItem === item) {
      setExpandedItem(null);
    } else {
      setExpandedItem(item);
    }
  };

  // Conditionally render the components inside return statement only when faqItems length is greater than 0
  return (
    <>
      {faqItems.length > 0 && (
        <>
          <h2 id="faqs-heading">
            Any <span>Queries?</span>
          </h2>
          <div className="landing-page-seven">
            {localFaqItems.map((faq, index) => {
              const isExpanded = expandedItem === index;
              const containerStyle = {
                overflow: isExpanded ? "visible" : "hidden",
              };

              const cardStyle = {
                maxHeight: isExpanded ? "1000px" : "0",
                transition: `max-height 0.5s ease`,
              };

              return (
                <div
                  key={index}
                  className="faqs-container"
                  style={containerStyle}
                  onClick={() => toggleExpand(index)}
                >
                  <div className="faqs">
                    <p>{faq.question}</p>
                    <img
                      src={expandImg}
                      alt="expand"
                      className="expand-icon"
                      style={{
                        transform: `rotate(${isExpanded ? "180deg" : "0"})`,
                        transition: `transform 0.5s ease`,
                      }}
                      onClick={() => toggleExpand(index)}
                    />
                  </div>
                  <div className="faq-card" style={cardStyle}>
                    <p className="faq-content">
                      <br />
                      {faq.answer}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </>
      )}
    </>
  );
}
