import React, { useEffect, useState } from 'react';
import './AdLandingPageOne.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function AdLandingPageOne() {
  const [adData, setAdData] = useState(null);

  useEffect(() => {
    client
      .fetch('*[_type == "adLandingPageOne"][0]')
      .then((data) => {
        console.log('Fetched ad data:', data);
        setAdData(data);
      })
      .catch((error) => console.error('Error fetching ad data:', error));
  }, []);

  if (!adData) {
    return <div></div>;
  }

  const { heading, description, image } = adData;

  return (
    <div id='add-landing-page-one'>
      <div id='add-landing-page-one-left'>
        <h1>{heading}</h1>
        <p>{description}</p>
      </div>
      <div id='add-landing-page-one-right'>
        <img src={urlFor(image)} alt='success' />
      </div>
    </div>
  );
}

