import React, {useEffect, useState} from 'react';
import Navbar from './navbar/Navbar';
import AdLandingPageOne from './advertiesWithUs/adLandingPageOne/AdLandingPageOne';
import AdLandingPageTwo from './advertiesWithUs/adLandingPageTwo/AdLandingPageTwo';
// import AdLandingPageThree from './advertiesWithUs/adLandingPageThree/AdLandingPageThree';
import AdLandingPageFour from './advertiesWithUs/adLandingPageFour/AdLandingPageFour';
import ReusableComponents from './commonReusableComponents/ReusableComponents';
import client from '../sanityConfig/sanityClient';




export default function AdvertiesWithUs() { 

  const [faqItems, setFaqItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  let fetcheFaqItems = [];

  
useEffect(() => {
  let passedValue = localStorage.getItem('myData');
  if (passedValue !== "true") {
    window.location.reload();
    localStorage.setItem('myData', "true");
  }
  client
  .fetch('*[_type == "faqAdvertisePageSchema"][0]')
  .then((data) => {
    setFaqItems(data["faqItems"]);
    fetcheFaqItems = data["faqItems"];
  })
  .catch((error) => console.error('Error fetching about data:', error)).finally(() => {setFaqItems(faqItems); });

    const intervalId = setInterval(() => {
      // Place your code here. This block will run every 30 seconds

      // Anonymous self-invoking function
      (function() {
        setFaqItems(fetcheFaqItems)
      })();

    }, 30); // 30000 ms = 30 seconds

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
    
}, []);
  return (
    <>
     <Navbar />
     <AdLandingPageOne />
     <AdLandingPageTwo />
     <AdLandingPageFour />
     { faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
    </>
  )
}
