// PolicyPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import './PolicyPage.css';
import client from '../../sanityConfig/sanityClient';


export default function PolicyPage() {
  const [sections, setSections] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);
  const [isListOpen, setIsListOpen] = useState(false);
  const contentRefs = useRef([]);

  useEffect(() => {
    let passedValue = localStorage.getItem('myData');
    if(passedValue !== "true"){
        window.location.reload();
        localStorage.setItem('myData', "true");
    }
    client
      .fetch('*[_type == "policyPage"][0]')
      .then((data) => {
        setSections(data.sections);
      })
      .catch((error) => console.error('Error fetching policy page data:', error));
  }, []);

  const handleClick = (index) => {
    setActiveIndex(index);
    setIsListOpen(false);
    const contentRef = contentRefs.current[index];
    contentRef.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div id="policy-page">
      <section id="policy-page-left" className={isListOpen ? 'open' : ''}>
        {isListOpen && (
          <button className="close-button" onClick={() => setIsListOpen(false)}>
            ✕
          </button>
        )}
        {!isListOpen && (
          <button className="open-button" onClick={() => setIsListOpen(true)}>
            ☰
          </button>
        )}
        <div className="title-list">
          {sections.map((section, index) => (
            <p
              key={index}
              className={index === activeIndex ? 'active' : ''}
              onClick={() => handleClick(index)}
            >
              {section.title}
            </p>
          ))}
        </div>
      </section>
      <section id="policy-page-right">
        {sections.map((section, index) => (
          <div
            key={index}
            className={index === activeIndex ? 'active' : ''}
            ref={(el) => (contentRefs.current[index] = el)}
          >
            <h2>{section.title}</h2>
            <p>{section.content}</p>
          </div>
        ))}
      </section>
    </div>
  );
}
