import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './PartnerlandingPageOne.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function PartnerlandingPageOne() {
    const [partnerData, setPartnerData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "partnerLandingPageOne"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setPartnerData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!partnerData) {
        return <div></div>;
    }

    const { title, description, phoneImage1, phoneImage2, buttonText1, buttonUrl1, buttonText2, buttonUrl2 } = partnerData;

    return (
        <div id='partner-landing-page-one'>
            <div id='partner-landing-page-one-left'>
                <h1>{title}</h1>
                <p>{description}</p>
                <div id='partner-landing-page-one-left-button'>
                    <Link to={buttonUrl1}>
                        <button id="first-btn">{buttonText1}</button>
                    </Link>
                    <Link to={buttonUrl2}>
                        <button id="second-btn">{buttonText2}</button>
                    </Link>
                </div>
            </div>
            <div id='partner-landing-page-one-right'>
                <img src={urlFor(phoneImage1)} alt='Phone Image 1' />
            </div>

            {/* phone view code */}

            <div id='partner-hero-container'>
                <div id='partner-hero-image-container'>
                    <img src={urlFor(phoneImage2)} alt='Phone Image 2' />
                </div>
                <div id='partner-hero-text-container'>
                    <h5>{title}</h5>
                    <p>{description}</p>
                    <Link to={buttonUrl1}>
                        <button id="mobile-view-first-btn">{buttonText1}</button>
                    </Link>
                    <Link to={buttonUrl2}>
                        <button id="mobile-view-second-btn">{buttonText2}</button>
                    </Link>
                </div>
            </div>
        </div>
    )
}








// // import React from 'react';
// // import { Link } from 'react-router-dom';
// // import './PartnerlandingPageOne.css';
// // import phoneImg1 from './Group 39960.svg';
// // import phoneImg2 from './Group 39953.svg';
// // import frontCamera from './Pill.svg';

// export default function PartnerlandingPageOne() {
//   return (
//     <div id='partner-landing-page-one'>
//       <div id='partner-landing-page-one-left'>
//         <h1>Partner with Stuffin at 0% for the first month</h1>
//         <p>"Need assistance? Feel free to reach out to us at +91 8969745628. We're here to help!"</p>
//         <div id='partner-landing-page-one-left-button'>
//           <Link to="https://admin.stuffin.in/partner/auth/sign_up">
//             <button id="first-btn">Register your tiffin center</button>
//           </Link>
          

//           <Link to="https://admin.stuffin.in/partner/login">
//           <button id="second-btn">Log in to your existing tiffen center</button></Link>
//         </div>
//       </div>
//       <div id='partner-landing-page-one-right'>
//         <img src={phoneImg1} alt='/' />
//       </div>

//       {/* phone view code */}

//       <div id='partner-hero-container'>
//         <div id='partner-hero-image-container'>
//           <img src={phoneImg2} alt='/' />
//         </div>
//         <div id='partner-hero-text-container'>
//           <h5>Partner with Stuffin<br />
//             at 0% for the first month</h5>
//           <p>"Need assistance? Feel free to reach out to us at +91 8969745628. We're here to help!"</p>
//           <Link to="https://admin.stuffin.in/partner/auth/sign_up">
//           <button id="mobile-view-first-btn">Resgiter your tiffen center</button>
//           </Link>
//           <Link to="https://admin.stuffin.in/partner/login">
//           <button id="mobile-view-second-btn">Log in to your existing tiffen center</button>
//           </Link>
//         </div>
//       </div>
//     </div>
//   )
// }
