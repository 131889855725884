// UserReviewContainer.js

import React from 'react';
import './UserReviewContainer.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const UserReviewContainer = ({review, userImage, userName }) => {
  return (
    <div className='user-review-container'>
      <div className='user-review-info'>
        <span className='quotation-sign'>&ldquo;</span>
        <p className='review-text'>
          <span>{review}</span>
        </p>
        <p>
          <span className='review-border-line'></span>
        </p>
        <div className='user-details'>
          <img src={urlFor(userImage)} alt='User' />
          <small>{userName}</small>
        </div>
      </div>
    </div>
  );
};

export default UserReviewContainer;

