import React, { useState, useEffect } from 'react';
import LandingPageSeven from '../homePage/seventhLandingPage/LandingPageSeven';
import LandingPageEight from '../homePage/eighthLandingPage/LandingPageEight';
import LandingPageNine from '../homePage/ninthLangingPage/LandingPageNine';
import { useLocation } from 'react-router-dom';

export default function ReusableComponents({ faqItems = [] }) {
  // Use useState to manage the state of faqItems
  const [localFaqItems, setLocalFaqItems] = useState(faqItems);
  let location = useLocation();

  // useEffect can be used here to update localFaqItems if faqItems prop changes
  useEffect(() => {
    setLocalFaqItems(faqItems);
    // console.log(`identifier ${JSON.stringify(faqItems)}`);
  }, [faqItems]);

  return (
    <>
      {/* Pass localFaqItems as prop to LandingPageSeven */}
      {localFaqItems.length > 0 && <LandingPageSeven faqItems={localFaqItems} />}
      {!location.state &&
        <LandingPageEight />}
        
      <LandingPageNine />
    </>
  );
}
