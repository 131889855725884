// LandingPageThree.js

import React, { useState, useEffect } from 'react';
import UserReviewContainer from './UserReviewContainer'; 
import './LandingPageThree.css';
import client from '../../../sanityConfig/sanityClient';

import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

const LandingPageThree = () => {
  const [data, setData] = useState(null);
  const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

  useEffect(() => {
    client
      .fetch('*[_type == "landingPageThree"][0]')
      .then((result) => {
        setData(result);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (!data) {
    return <div></div>;
  }

  const { desktopHeading, mobileHeading, userReviews, frontBtn, backBtn } = data;

  const handleNextReview = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % userReviews.length);
  };

  const handlePrevReview = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? userReviews.length - 1 : prevIndex - 1
    );
  };

  const handleNextReviewDesktop = () => {
    setCurrentReviewIndex((prevIndex) => (prevIndex + 2) % userReviews.length);
  };

  const handlePrevReviewDesktop = () => {
    setCurrentReviewIndex((prevIndex) =>
      prevIndex === 0 ? userReviews.length - 2 : prevIndex - 2
    );
  };

  return (
    <>
      <div id='landing-page-three'>
        <div id='page-three-heading'>
          <h1>{desktopHeading}</h1>
          <div id='increment-decrement-btn'>
            <section id='review-back-btn' onClick={handlePrevReviewDesktop}>
              <img src={urlFor(backBtn)} alt='Previous' />
            </section>
            <section id='review-forward-btn' onClick={handleNextReviewDesktop}>
              <img src={urlFor(frontBtn)} alt='Next' />
            </section>
          </div>
        </div>

        {/* Display only two containers at a time */}
        {userReviews.slice(currentReviewIndex, currentReviewIndex + 2).map((review, index) => (
          <UserReviewContainer
            key={index}
            quotation={review.quotation}
            review={review.review}
            userImage={review.userImage}
            userName={review.userName}
            onPrevReview={handlePrevReview}
            onNextReview={handleNextReview}
          />
        ))}
      </div>

      <div className='mobile-view-landing-page-three'>
        {/* Show this container when the screen size is less than 600px */}
        <h1>{mobileHeading}</h1>
        <div className='user-container-mobile-view'>
          {/* Display only one container at a time */}
          {userReviews.slice(currentReviewIndex, currentReviewIndex + 1).map((review, index) => (
            <UserReviewContainer
              key={index}
              quotation={review.quotation}
              review={review.review}
              userImage={review.userImage}
              userName={review.userName}
              onPrevReview={handlePrevReview}
              onNextReview={handleNextReview}
            />
          ))}
          <div className='next-prev-btn-mobile'>
            <button className='left-btn' onClick={handlePrevReview}>
              <img src={urlFor(backBtn)} alt='Previous' />     
            </button>
          </div>
          <div className='next-next-btn-mobile'>
            <button className='right-btn' onClick={handleNextReview}>
              <img src={urlFor(frontBtn)} alt='Next' />
            </button>
          </div>
          {/* Buttons over the user review container */}
        </div>
      </div>
    </>
  );
};

export default LandingPageThree;




// import React, { useState } from 'react';
// import './LandingPageThree.css';
// import frontBtn from './chevron-left.svg';
// import backBtn from './chevron-right.svg';
// import UserReviewContainer from './UserReviewContainer'; // Update the path accordingly


// export default function LandingPageThree() {
//   const [currentReviewIndex, setCurrentReviewIndex] = useState(0);

//   const handleNextReview = () => {
//     setCurrentReviewIndex((prevIndex) => (prevIndex + 1) % userReviews.length);
//   };

//   const handlePrevReview = () => {
//     setCurrentReviewIndex((prevIndex) =>
//       prevIndex === 0 ? userReviews.length - 1 : prevIndex - 1
//     );
//   };

//   const handleNextReviewDesktop = () => {
//     setCurrentReviewIndex((prevIndex) => (prevIndex + 2) % userReviews.length);
//   };

//   const handlePrevReviewDesktop = () => {
//     setCurrentReviewIndex((prevIndex) =>
//       prevIndex === 0 ? userReviews.length - 2 : prevIndex - 2
//     );
//   };

//   const userReviews = [
//     {
//       quotation: 'Sample quotation 1.',
//       review: '“The lifesaver for hostelites! 🍛 Stuffin has made my life so much easier. No more maggi dinners, now I get ghar ka khana every day. The tiffin providers even know my name now! 🥺 It feels like home. Big thumbs up 👍 to Stuffin!"',
//       userImage: require('./userImg.svg').default,
//       userName: 'Ravi Chandran',
//     },
//     {
//       quotation: 'Sample quotation 2.',
//       review: '“Stuffin is a blessing for people like me who are always on the run! 🏃‍♀️ The lunchbox is always on time, and the food...oh, it takes me back to my Ma’s kitchen. 🏡 Simple, tasty, and always with a touch of love. ❤️ Thank you, Stuffin!"',
//       userImage: require('./userImg.svg').default,
//       userName: 'Ravish Kumar',
//     },

//     {
//       quotation: 'Sample quotation 2.',
//       review: '“Stuffin is a blessing for people like me who are always on the run! 🏃‍♀️ The lunchbox is always on time, and the food...oh, it takes me back to my Ma’s kitchen. 🏡 Simple, tasty, and always with a touch of love. ❤️ Thank you, Stuffin!"',
//       userImage: require('./userImg.svg').default,
//       userName: 'Rohit Sharma',
//     },

//     {
//       quotation: 'Sample quotation 2.',
//       review: '“Stuffin is a blessing for people like me who are always on the run! 🏃‍♀️ The lunchbox is always on time, and the food...oh, it takes me back to my Ma’s kitchen. 🏡 Simple, tasty, and always with a touch of love. ❤️ Thank you, Stuffin!"',
//       userImage: require('./userImg.svg').default,
//       userName: 'Glen Maxwell',
//     },
//     // Add more reviews as needed
//   ];

//   return (
//     <>
//       <div id='landing-page-three'>
//         <div id='page-three-heading'>
//           <h1>What Our Users Say !</h1>
//           <div id='increment-decrement-btn'>
//             <section id='review-back-btn' onClick={handlePrevReviewDesktop}>
//               <img src={backBtn} alt='Previous' />
//             </section>
//             <section id='review-forward-btn' onClick={handleNextReviewDesktop}>
//               <img src={frontBtn} alt='Next' />
//             </section>
//           </div>
//         </div>

//         {/* Display only two containers at a time */}
//         {userReviews.slice(currentReviewIndex, currentReviewIndex + 2).map((review, index) => (
//           <UserReviewContainer
//             key={index}
//             quotation={review.quotation}
//             review={review.review}
//             userImage={review.userImage}
//             userName={review.userName}
//             onPrevReview={handlePrevReview}
//             onNextReview={handleNextReview}
//           />
//         ))}
//       </div>

//       <div className='mobile-view-landing-page-three'>
//         {/* Show this container when the screen size is less than 600px */}
//         <h1 className='users-heading'>What Our Users Say!</h1>
//         <div className='user-container-mobile-view'>
//           {/* Display only one container at a time */}
//           {userReviews.slice(currentReviewIndex, currentReviewIndex + 1).map((review, index) => (
//             <UserReviewContainer
//               key={index}
//               quotation={review.quotation}
//               review={review.review}
//               userImage={review.userImage}
//               userName={review.userName}
//               onPrevReview={handlePrevReview}
//               onNextReview={handleNextReview}
//             />

//           ))}
//           <div className='next-prev-btn-mobile'>
//             <button className='left-btn' onClick={handlePrevReview}>
//               <img src={backBtn} alt='Previous' />
//             </button>

//           </div>
//           <div className='next-next-btn-mobile'>
//             <button className='right-btn' onClick={handleNextReview}>
//               <img src={frontBtn} alt='Next' />
//             </button>
//           </div>
//           {/* Buttons over the user review container */}
//         </div>

//       </div>
//     </>
//   );
// }