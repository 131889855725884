import React, { useEffect, useState } from 'react';
import './AboutUsFirstLandingPage.css';
import downArrow from './down-arrow.svg';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function AboutUsFirstLandingPage() {
  const [aboutData, setAboutData] = useState(null);

  const handleScroll = () => {
    const aboutSection = document.getElementById('updated-second-container');
    const triggerHeight = window.innerHeight * 0.7; // Adjust the trigger height as needed

    if (aboutSection && window.scrollY > aboutSection.offsetTop - triggerHeight) {
      scrollToAboutSection();
      window.removeEventListener('scroll', handleScroll); // Remove the scroll listener after triggering once
    }
  };

  useEffect(() => {
    client
      .fetch('*[_type == "aboutUsFirstLandingPage"][0]')
      .then((data) => {
        console.log('Fetched about data:', data);
        setAboutData(data);
      })
      .catch((error) => console.error('Error fetching about data:', error));

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  if (!aboutData) {
    return <div></div>;
  }

  const {
    heroHeading,
    heroDescription,
    heroImage,
    storySection,
    missionSection,
    visionSection,
    teamMembers,
  } = aboutData;

  function scrollToAboutSection() {
    const aboutSection = document.getElementById('updated-second-container');

    if (aboutSection) {
      aboutSection.scrollIntoView({
        behavior: 'smooth',
      });
    }
  };

  return (
    <div id="about-landing-page">
      <div id="about-landing-page-hero-container">
        <div id="left-hero">
          <h1>
            <span>{heroHeading}</span>
          </h1>
          <p>{heroDescription}</p>
          <button id="read-more-button" onClick={scrollToAboutSection} onTouchStart={scrollToAboutSection}>
            <span>Read more</span>
            <img src={downArrow} alt="/" /> {/* Use urlFor for downArrow */}
          </button>
        </div>
        <div id="right-hero">
          <img src={urlFor(heroImage)} alt="/" /> {/* Use urlFor for heroImage */}
        </div>
      </div>
      <div id="updated-second-container">
        {/* Story Section */}
        <div id="story-updated">
          <img src={urlFor(storySection.storyImage)} alt="/story-image-1" /> {/* Use urlFor for storyImage */}
          <h2>{storySection.storyHeading}</h2>
          <p>{storySection.storyDescription}</p>
        </div>
        {/* Mission Section */}
        <div id="mission-updated">
          <img src={urlFor(missionSection.missionImage)} alt="/story-image-2" /> {/* Use urlFor for missionImage */}
          <h2>{missionSection.missionHeading}</h2>
          <p>{missionSection.missionDescription}</p>
        </div>
        {/* Vision Section */}
        <div id="vission-updated">
          <img src={urlFor(visionSection.visionImage)} alt="/story-image-3" /> {/* Use urlFor for visionImage */}
          <h2>{visionSection.visionHeading}</h2>
          <p>{visionSection.visionDescription}</p>
        </div>
      </div>
      {/* Team Members */}
      <div>
        <h1 id="about-landing-page-third-heading">
          <span>Team</span>
        </h1>
        <div id="about-landing-page-third-container">
          {teamMembers.map((member, index) => (
            <div key={index} id="team-info">
              <img src={urlFor(member.image)} alt={member.name} /> {/* Use urlFor for member image */}
              <div id="member-info">
                <p>{member.name}</p>
                <div id="icon-img">
                  <a href={member.twitterIcon.url} target="_blank" rel="noopener noreferrer">
                    <img src={urlFor(member.twitterIcon.icon)} alt="/twitter" /> {/* Use urlFor for Twitter icon */}
                  </a>
                  <a href={member.linkedinIcon.url} target="_blank" rel="noopener noreferrer">
                    <img src={urlFor(member.linkedinIcon.icon)} alt="/Linkedin" /> {/* Use urlFor for LinkedIn icon */}
                  </a>
                </div>
              </div>
              <span>{member.position}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
