// LandingPageTwo.js
import React, { useState, useEffect } from 'react';
import './LandingPageTwo.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function LandingPageTwo() {
    const [data, setData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "landingPageTwo"][0]')
            .then((result) => {
                // console.log('Fetched data:', result);
                setData(result);
            })
            .catch((error) => console.error('Error fetching data:', error));
    }, []);

    if (!data) {
        return <div></div>;
    }

    const { leftPreferences, leftRightSectionPreferences, rightSection } = data;

    return (
        <div id='landing-page-two'>
            <div id='landing-page-two-left'>
                <div id='landing-page-two-leftside'>
                    {leftPreferences.map((preference, index) => (
                        <div id='our-prefrences' key={index}>
                            <img src={urlFor(preference.image)} alt='/' />
                            <h2>
                                {preference.title} <span>{preference.spanText}</span>
                            </h2>
                            <p>{preference.description}</p>
                        </div>
                    ))}
                </div>
                <div id='landing-page-two-left-rightside'>
                    <img src={urlFor(leftRightSectionPreferences.mainImage)} alt='/' />
                    <h2>{leftRightSectionPreferences.mainTitle} <span>{leftRightSectionPreferences.qualitySpan}</span></h2>
                    <p>{leftRightSectionPreferences.mainDescription}</p>
                </div>
            </div>
            <div id='landing-page-two-right'>
                <h1>{rightSection.rightHeading}</h1>
                <p>
                    {rightSection.rightDescription}
                </p>
            </div>
        </div>
    );

}

















// import React from 'react';
// import './LandingPageTwo.css';
// import bikeImg from '../../../assets/bike2.svg';
// import breakfastImg from '../../../assets/Breakfast-menu.svg';
// import qualityGif from '../../../assets/quality.gif.svg';
// export default function LandingPageTwo() {
//     return (
//         <div id='landing-page-two'>
//             <div id='landing-page-two-left'>
//                 <div id='landing-page-two-leftside'>
//                     <div id='our-prefrences'>
//                         <img src={breakfastImg} alt='/' />
//                         <h2>Easy to <span>Order</span></h2>
//                         <p>Craving a meal? With a few clicks, your favourite tiffin is on its way! Experience the ease of ordering with Stuffin.</p>
//                     </div>

//                     <div id='our-prefrences'>
//                         <img src={bikeImg} alt='/' />
//                         <h2>Timely <span>Delivery</span></h2>
//                         <p>Tick-tock, it’s meal o’clock! Rely on Stuffin for punctual tiffin delivery, making your dining routine seamless and enjoyable</p>
//                     </div>
//                 </div>
//                 <div id='landing-page-two-left-rightside'>
//                     <img src={qualityGif} alt='/' />
//                     <h2>Best <span>Quality</span></h2>
//                     <p>Indulge in the finest! Our lunch boxes are filled with top-notch, home-cooked goodness, ensuring every bite is a delight.</p>
//                 </div>
//             </div>
//             <div id='landing-page-two-right'>
//                 <h1>Your Tiffin <span>Delivery Partner</span></h1>
//                 <p>Discover the joy of fresh, home-cooked meals with Stuffin! Based in Patna, we deliver breakfast, lunch, and dinner right to your doorstep. Choose from a variety of local tiffin service providers and enjoy nutritious meals tailored for students and working professionals. Subscribe now for a hassle-free dining experience!</p>
//             </div>

//         </div>
//     )
// }
