import React from 'react';
import { useEffect, useState } from 'react';
import RideLandingPageOne from './rideWithus/RideLandingPageOne';
import Navbar from './navbar/Navbar';
import RideLandingPageTwo from './rideWithus/RideLandingPageTwo';
import RideLandingPageThree from './rideWithus/RideLandingPageThree';
import RideLandingPageFour from './rideWithus/RideLandingPageFour';
import LandingPageThree from '../components/homePage/thirdLandingPage/LandingPageThree.js';
import ReusableComponents from './commonReusableComponents/ReusableComponents';
import client from '../sanityConfig/sanityClient.js';

export default function RideWithUs() {
  const [faqItems, setFaqItems] = useState([]);
  // const [loading, setLoading] = useState(true);

  let fetcheFaqItems = [];

  useEffect(() => {
    let passedValue = localStorage.getItem('myData');
    if(passedValue !== "true"){
        window.location.reload();
        localStorage.setItem('myData', "true");
    }
    client
    .fetch('*[_type == "faqRidePageSchema"][0]')
    .then((data) => {
      setFaqItems(data["faqItems"]);
      fetcheFaqItems = data["faqItems"];
    })
    .catch((error) => console.error('Error fetching about data:', error)).finally(() => {setFaqItems(faqItems); });

      const intervalId = setInterval(() => {
  
        (function() {
          setFaqItems(fetcheFaqItems)
        })();
  
      }, 30); // 30000 ms = 30 seconds
  
      // Cleanup interval on component unmount
      return () => clearInterval(intervalId);
      
  }, []);
  return (
    <>
      <Navbar />
      <br />
      <RideLandingPageOne />
      <br />
      <RideLandingPageTwo />
      <RideLandingPageThree />
      <RideLandingPageFour />
      <br />
      <LandingPageThree />
      { faqItems.length > 0 && <ReusableComponents faqItems={faqItems} />}
    </>
  )
}
