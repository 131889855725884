// LandingAboutPage.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './LandingPageSix.css';
import client from '../../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
  return builder.image(source);
}

export default function LandingAboutPage() {
  const [landingPageData, setLandingPageData] = useState(null);

  useEffect(() => {
    let passedValue = localStorage.getItem('myData');
    if (passedValue !== "true") {
      window.location.reload();
      localStorage.setItem('myData', "true");
    }

    client
      .fetch('*[_type == "landingAboutPage"][0]')
      .then((result) => {
        // console.log('Fetched data:', result);
        setLandingPageData(result);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  if (!landingPageData) {
    return <div></div>;
  }

  const { title, paragraphText, buttonText, buttonImage, buttonRoute, image } = landingPageData;

  return (
    <div id="landing-page-six">
      <h2>{title}</h2>
      <div id='landing-page-six-container'>
        <div id='landing-page-six-left'>
        <h1>{title}</h1>
          <p>{paragraphText}</p>
          <Link to={buttonRoute} onClick={() => {   // Dynamically use buttonRoute from CMS
            localStorage.setItem('myData', "false");
          }}>
            <button>
              <span>{buttonText}</span>
              <img src={urlFor(buttonImage)} alt='Read More' />
            </button>
          </Link>
        </div>
        <div id='landing-page-six-right'>
          <img src={urlFor(image)} alt='Food Delivery' />
        </div>
      </div>
    </div>
  );
}





// import React, { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import './LandingPageSix.css';
// import foodDeliveryImg from './Food delivery to customer home@1x-2.svg';
// import readMoreBtn from './readMore.svg';

// export default function LandingPageSix() {

//     useEffect(() => {
//         let passedValue = localStorage.getItem('myData');
//         if (passedValue !== "true") {
//             window.location.reload();
//             localStorage.setItem('myData', "true");
//         }

//         // 👇️ scroll to top on page load
//     }, []);

//     return (
//         <div id="landing-page-six">
//             <h1>About<span> us</span></h1>
//             <div id='landing-page-six-container'>
//                 <div id='landing-page-six-left'>
//                     <p>
//                         Welcoming you to Stuffin, where we redefine the tiffin delivery experience in Patna! From the get-go, our platform connects you to a variety of local lunchbox providers, ensuring a warm, home-cooked meal is just a click away. We pride ourselves on timely delivery and top-quality mess service, aiming to bring delight to students and working professionals alike. Dive into the world of diverse, delicious, and dependable tiffin services with Stuffin </p>
//                     <Link to='/about' onClick={() => {
//                         localStorage.setItem('myData', "false");
//                     }}>
//                         <button>
//                             <span >Read More</span>
//                             <img src={readMoreBtn} alt='/' />
//                         </button>
//                     </Link>
//                 </div>
//                 <div id='landing-page-six-right'>
//                     <img src={foodDeliveryImg} alt='/' />
//                 </div>
//             </div>
//         </div>
//     )
// }
