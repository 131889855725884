import React, {useEffect} from 'react';
import PolicyPage from './policyPage/PolicyPage';
import Navbar from './navbar/Navbar';
import LandingPageNine from './homePage/ninthLangingPage/LandingPageNine';

export default function PolicyPageComponent() {
  useEffect (() =>
  {
    let passedValue = localStorage.getItem('myData');
    if(passedValue !== "true"){
        window.location.reload();
        localStorage.setItem('myData', "true");
    }
  }, [])
  

  return (
    <>
      <Navbar />
      <PolicyPage />
      <br />
      <LandingPageNine />
    </>
  )
}
