
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './RideLandingPageThree.css';
import client from '../../sanityConfig/sanityClient';
import imageUrlBuilder from '@sanity/image-url';

const builder = imageUrlBuilder(client);

function urlFor(source) {
    return builder.image(source);
}

export default function RideLandingPageThree() {
    const [pageData, setPageData] = useState(null);

    useEffect(() => {
        client
            .fetch('*[_type == "rideLandingPageThree"][0]')
            .then((data) => {
                setPageData(data);
            })
            .catch((error) => console.error('Error fetching page data:', error));
    }, []);

    if (!pageData) {
        return <div></div>;
    }

    const { rideImage, title, listItemImage, listItemPhoneImage, buttonText, buttonLink } = pageData;

    return (
        <div id='ride-landing-page-three'>
            <div id='ride-landing-page-three-container'>
                <div id='ride-landing-page-three-container-left'>
                    <img src={urlFor(rideImage)} alt='Ride' />
                </div>
                <div id='ride-landing-page-three-container-right'>
                    <h1>{title}</h1>
                    <div className='getStarted-list'>
                        <img src={urlFor(listItemImage)} alt='List Item' />
                    </div>
                    <div className='getStarted-list-phoneView'>
                        <img src={urlFor(listItemPhoneImage)} alt='List Item Phone' />
                    </div>
                    <button>
                        <Link to={buttonLink} onClick={() => {
                            localStorage.setItem('myData', "false");
                        }}>{buttonText}</Link>
                    </button>
                </div>
            </div>
        </div>
    );
}


// import React from 'react';
// import{ Link } from 'react-router-dom';
// import './RideLandingPageThree.css';
// import rideImg from './Frame 1027.svg';
// import listItem from './Group 39961.svg';
// import listItemPhone from './Group 39945.svg';

// import client from '../../sanityConfig/sanityClient';
// import imageUrlBuilder from '@sanity/image-url';

// const builder = imageUrlBuilder(client);

// function urlFor(source) {
//     return builder.image(source);
// }



// export default function RideLandingPageThree() {
//     return (
//         <div id='ride-landing-page-three'>
//             <div id='ride-landing-page-three-container'>
//                 <div id='ride-landing-page-three-container-left'>
//                     <img src={rideImg} alt='/' />
//                 </div>
//                 <div id='ride-landing-page-three-container-right'>
//                     <h1>Get started in <span>4 easy steps</span></h1>
//                     <div className='getStarted-list'>
//                         <img src={listItem} alt='/' />
//                     </div>
//                     <div className='getStarted-list-phoneView'>
//                         <img src={listItemPhone} alt='/' />
//                     </div>
//                     <button>
//                         <Link to="/contact" onClick={() => {// Reload the current page
//                             localStorage.setItem('myData', "false");
//                         }}>Get started</Link>
//                     </button>
//                 </div>
//             </div>
//         </div>
//     )
// }
